<template>
  <MasterLayout :main_name="main_name" :link="link">
    <div
      class="sidebar_right_content profile_block"
      v-if="dashboardBooking.length > 0"
    >
      <div class="city">
        <a @click="linkBooking()" class="add_icon" v-show="!form.id">
          <span><i class="fa fa-plus" aria-hidden="true"></i></span> 
        </a>
        <div v-if="dashboardBooking.length > 0">
          <div
            class="tab_inner_content"
            :style="{
              backgroundImage: 'url(' + banner02 + ')',
              'border-radius': '10px',
            }"
          >
            <div
              class="tab_inner_content_main row"
              v-for="card in dashboardBooking"
              :key="card.id"
            >
              <div
                class="d_flex col-sm"
                v-if="card.type == 'EventBooking' && card.event"
              >
                <div
                  class="tab_inner_content_image"
                  v-show="card.event.event_image[0]"
                >
                  <img :src="card.event.event_image[0].image" />
                </div>
                <div class="tab_inner_content_date">
                  <span>{{ card.date_format }}</span>
                  <div v-for="villaData in card.villa" :key="villaData">
                    <p>
                      {{
                        villaData.first_name == "null" ||
                        villaData.first_name == ""
                          ? ""
                          : villaData.first_name
                      }}
                      {{
                        villaData.middle_name == "null" ||
                        villaData.middle_name == ""
                          ? ""
                          : villaData.middle_name
                      }}
                      {{
                        villaData.last_name == "null" ||
                        villaData.last_name == ""
                          ? ""
                          : villaData.last_name
                      }}
                    </p>
                    <p>{{ card.event.name }}</p>
                  </div>
                </div>
              </div>
              <div
                class="d_flex col-sm"
                v-else-if="card.type == 'OneDayPicnic'"
              >
                <div class="tab_inner_content_image">
                  <img
                    src="../../../public/images/inner_page/about_us_01.jpg"
                  />
                </div>
                <div class="tab_inner_content_date">
                  <span>{{ card.date_format }}</span>
                  <div v-for="villaData in card.villa" :key="villaData">
                    <p>
                      {{
                        villaData.first_name == "null" ||
                        villaData.first_name == ""
                          ? ""
                          : villaData.first_name
                      }}
                      {{
                        villaData.middle_name == "null" ||
                        villaData.middle_name == ""
                          ? ""
                          : villaData.middle_name
                      }}
                      {{
                        villaData.last_name == "null" ||
                        villaData.last_name == ""
                          ? ""
                          : villaData.last_name
                      }}
                    </p>
                    <p>One Day Picnic</p>
                  </div>
                </div>
              </div>
              <div class="d_flex col-sm" v-else>
                <div
                  class="tab_inner_content_image"
                  v-show="card.villa[0].villa_type_master"
                >
                  <img
                    :src="
                      card.villa[0].villa_type_master
                        ? card.villa[0].villa_type_master.villa_images[0]
                        : ``
                    "
                  />
                </div>
                <div class="tab_inner_content_date">
                  <span>{{ card.date_format }}</span>
                  <div v-for="villaData in card.villa" :key="villaData">
                    <p>
                      {{
                        villaData.first_name == "null" ||
                        villaData.first_name == ""
                          ? ""
                          : villaData.first_name
                      }}
                      {{
                        villaData.middle_name == "null" ||
                        villaData.middle_name == ""
                          ? ""
                          : villaData.middle_name
                      }}
                      {{
                        villaData.last_name == "null" ||
                        villaData.last_name == ""
                          ? ""
                          : villaData.last_name
                      }}
                    </p>
                    <p>
                      {{
                        villaData.villa_type_master
                          ? villaData.villa_type_master.villa_name
                          : ""
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="sidebar_right_content profile_block"
      v-else-if="dashboardBooking.length == 0 && booking_type == null"
    >
      <div class="city">
        <ul class="ui-formSlide">
          <li class="m15minus chooseDate">
            <div class="v_bk_cat">
              <img :src="frame" />
              <div class="v_con_main">You Have No Upcoming Booking</div>
              <div class="bk_btn">
                <a @click="linkBooking" class="site_bk_btn">
                  <span>Book Villa</span>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="sidebar_right_content profile_block" v-else>
      <div class="city" v-if="userProfile">
        <ul class="ui-formSlide">
          <li class="m15minus chooseDate" v-if="is_choose_date">
            <div class="bk_title text-center">Your can Book from here also</div>
            <div class="bk_title_main text-center">
              Accommodation Fit For
              <span class="font_bold"
                >{{ userProfile ? userProfile.first_name : "" }}'s</span
              >
              Profile
            </div>
            <div class="datePicker">
              <Datepicker
                type="date"
                :min="new Date().toISOString().substr(0, 10)"
                @closed="dateChange()"
                v-model="form.rangeDate"
                placeholder="Check In Check Out"
                format="dd-MM-yyyy"
                value-format="dd-MM-yyyy"
                :min-date="new Date()"
                modelAuto
                range
                multiCalendars
                multiCalendarsSolo
              />
              <div
                class="invalid-feedback fw-bold fst-italic text-danger mt-1"
                :style="{ display: checkInDateError }"
              >
                Please choose check-in check-out date.
              </div>
            </div>
            <div class="bk_title_info_info_main_row" v-if="form.rangeDate">
              <div v-if="userProfile.user_type == 'Guest'" class="w-100">
                <div v-if="villaList.length" class="villa_card_wrapper">
                  <div
                    class="bk_title_info_info"
                    v-for="(villa, index) in villaList"
                    :key="villa.villaTypeId"
                  >
                    <div
                      class="bk_title_info_info_inr"
                      @click="villaClick(villa.villaTypeId)"
                    >
                      <div
                        class="bk_title_info cursor_pointer"
                        :class="
                          clickVillaId.find((c) => c == villa.villaTypeId)
                            ? `activeClick`
                            : ``
                        "
                      >
                        <div class="bk_title_img">
                          <a
                            :href="villa.villa_images[0]"
                            data-lightbox="models"
                            data-title="STRUCTURE-2"
                          >
                            <img :src="villa.villa_images[0]" />
                          </a>
                          <!-- <span>View Vila</span> -->
                        </div>
                        <div class="bk_content">
                          <div class="vila_title">
                            {{ villa.villa_name.toLowerCase() }}
                          </div>
                          <div
                            class="vila_title_info"
                            :class="
                              clickVillaId.find((c) => c == villa.villaTypeId)
                                ? `activeClick`
                                : ``
                            "
                          >
                            <span> Max Quantity </span>
                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                fill="#153936"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                fill="#153936"
                              />
                            </svg>
                            <span>1 &nbsp;&nbsp; x &nbsp; </span>
                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                fill="#153936"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                fill="#153936"
                              />
                            </svg>
                            <span class="font_bold">{{
                              villa.allow_adult
                            }}</span>
                          </div>
                          <div
                            class="vila_title_info"
                            :class="
                              clickVillaId.find((c) => c == villa.villaTypeId)
                                ? `activeClick`
                                : ``
                            "
                          >
                            <span> Price </span>&nbsp;:&nbsp;
                            <span class="font_bold"
                              ><span class="rupee">₹</span
                              >{{
                                villa.price
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}.00</span
                            >
                          </div>
                          <div
                            class="vila_title_info"
                            :class="
                              clickVillaId.find((c) => c == villa.villaTypeId)
                                ? `activeClick`
                                : ``
                            "
                            v-if="villa.price != villa.weekend_price"
                          >
                            <span> Weekend Price </span>&nbsp;:&nbsp;
                            <span class="font_bold"
                              ><span class="rupee">₹</span
                              >{{
                                villa.weekend_price
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}.00</span
                            >
                          </div>
                          <div
                            class="vila_title_info"
                            :class="
                              clickVillaId.find((c) => c == villa.villaTypeId)
                                ? `activeClick`
                                : ``
                            "
                            v-if="villa.max_adult_limit"
                          >
                            <span> Max Adult Limit</span>&nbsp;:&nbsp;
                            <span class="font_bold">{{
                              villa.max_adult_limit
                            }}</span>
                          </div>
                          <div
                            class="vila_title_info"
                            :class="
                              clickVillaId.find((c) => c == villa.villaTypeId)
                                ? `activeClick`
                                : ``
                            "
                            v-if="villa.extra_per_person_charge"
                          >
                            <span> Extra Person</span>&nbsp;:&nbsp;
                            <span class="font_bold"
                              ><span class="rupee">₹</span
                              >{{
                                parseFloat(villa.extra_per_person_charge || 0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}.00</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="vialla_qty_main_inner" v-if="!villa.message">
                        <div class="vialla_qty_main">
                          <!-- <span>Villa Qty</span> -->
                          <div class="main_class_inner_sd select_image">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.11076 2.83302C7.4653 2.30311 6.53535 2.30311 5.88989 2.83302L2.76003 5.4026C2.49011 5.6242 2.33366 5.95507 2.33366 6.3043V10.5C2.33366 11.1443 2.85599 11.6666 3.50033 11.6666H10.5003C11.1447 11.6666 11.667 11.1443 11.667 10.5V6.3043C11.667 5.95507 11.5105 5.6242 11.2406 5.4026L8.11076 2.83302ZM5.1496 1.93131C6.22536 1.04812 7.77529 1.04812 8.85106 1.93132L11.9809 4.50089C12.5208 4.94409 12.8337 5.60583 12.8337 6.3043V10.5C12.8337 11.7886 11.789 12.8333 10.5003 12.8333H3.50033C2.21166 12.8333 1.16699 11.7886 1.16699 10.5V6.3043C1.16699 5.60583 1.4799 4.94409 2.01974 4.50089L5.1496 1.93131Z"
                                fill="#153936"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.66699 9.91671C4.66699 8.62804 5.71166 7.58337 7.00033 7.58337C8.28899 7.58337 9.33366 8.62804 9.33366 9.91671V12.25H8.16699V9.91671C8.16699 9.27238 7.64466 8.75004 7.00033 8.75004C6.35599 8.75004 5.83366 9.27238 5.83366 9.91671V12.25H4.66699V9.91671Z"
                                fill="#153936"
                              />
                            </svg>
                            <select
                              :id="`qty_${villa.villaTypeId}`"
                              name="villaQty"
                              v-model="villa_type[villa.pack_type].qty"
                              @change="
                                onChangeVillaQty(
                                  villa.villaTypeId,
                                  villa_type[villa.pack_type].qty,
                                  villa_type[villa.pack_type].person
                                )
                              "
                            >
                              <option :value="0">Select Qty</option>
                              <option
                                v-for="(vQty, vIndex) in villa.villa_quantity"
                                :key="villa.villaTypeId + '_' + vIndex"
                                :value="villa.villaTypeId + '_' + vQty"
                              >
                                {{ vQty }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="vialla_qty_main">
                          <!-- <span>Total Prax</span> -->
                          <div class="main_class_inner_sd select_image">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                                fill="#153936"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                                fill="#153936"
                              />
                            </svg>
                            <select
                              :id="`person_${villa.villaTypeId}`"
                              name="villaPerson"
                              v-model="villa_type[villa.pack_type].person"
                              :disabled="villa_type[villa.pack_type].qty <= 0"
                              @change="
                                onChangeVillaQty(
                                  villa.villaTypeId,
                                  villa_type[villa.pack_type].qty,
                                  villa_type[villa.pack_type].person
                                )
                              "
                            >
                              <option :value="0" disabled hidden>Person</option>
                              <option
                                v-for="(pQty, pIndex) in villa_booking[index]
                                  .quantity * villa.max_adult_limit"
                                :key="villa.villaTypeId + '_' + pIndex"
                                :value="villa.villaTypeId + '_' + pQty"
                              >
                                {{ pQty }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="vialla_qty_main_inner text-danger" v-else>
                        {{ villa.message }}
                      </div>
                      <p
                        class="reverse_error"
                        v-if="reserve_error[villa.pack_type].error"
                      >
                        {{ reserve_error[villa.pack_type].error }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="userProfile.user_type == 'Owner'" class="w-100">
                <div
                  class="villa_card_owner_wrapper"
                  v-if="is_owner_villa_avilable"
                >
                  <button
                    class="main_button_user"
                    type="submit"
                    :disabed="disabled"
                    @click="ownerSelfBooking()"
                  >
                    <span>Book For My Self</span>
                  </button>
                  <button
                    class="main_button_user"
                    type="submit"
                    @click="ownerOtherBookingShow()"
                  >
                    <span>Booking For Other</span>
                  </button>
                </div>
              </div>
              <div
                class="w-100 villa_card_owner_other_wrapper"
                v-if="
                  userProfile.user_type == 'Owner' &&
                  is_owner_other_booking_show
                "
              >
                <div class="row">
                  <div class="col-6">
                    <label>First Name<span style="color: red">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      name="first_name"
                      v-model="form.persons[0].first_name"
                    />
                    <div class="error" v-if="owner_errors?.first_name">
                      {{ owner_errors?.first_name[0] }}
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Middle Name<span style="color: red"></span></label>
                    <input
                      type="text"
                      class="form-control"
                      name="middle_name"
                      v-model="form.persons[0].middle_name"
                    />
                    <div class="error" v-if="owner_errors?.middle_name">
                      {{ owner_errors?.middle_name[0] }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>Last Name<span style="color: red">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      name="last_name"
                      v-model="form.persons[0].last_name"
                    />
                    <div class="error" v-if="owner_errors?.last_name">
                      {{ owner_errors?.last_name[0] }}
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Phone Number<span style="color: red">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      name="phone_number"
                      v-model="form.persons[0].mobile"
                    />
                    <div class="error" v-if="owner_errors?.phone_number">
                      {{ owner_errors?.phone_number[0] }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>Adults<span style="color: red">*</span></label>
                    <select
                      @change="selectAdults($event)"
                      class="form-control"
                      v-model="villa_booking[0].person"
                    >
                      <option value="0" hidden>Select</option>
                      <option
                        v-for="i in userProfile?.owner_details?.max_adult_limit"
                        :key="i"
                      >
                        {{ i }}
                      </option>
                    </select>
                    <div class="error" v-if="owner_errors?.adults">
                      {{ owner_errors?.adults[0] }}
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Children</label>
                    <select v-model="total_child" class="form-control">
                      <option value="0" hidden>Select</option>
                      <option v-for="i in 20" :key="i">{{ i }}</option>
                    </select>
                    <div class="error" v-if="owner_errors?.childrens">
                      {{ owner_errors?.childrens[0] }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-100 villa_card_owner_other_wrapper bg-transparent"
                v-if="
                  userProfile.user_type == 'Owner' &&
                  is_owner_other_booking_show
                "
              >
                <div class="row justify-center">
                  <div class="col-6">
                    <label class="check-box-wrapper" for="freeForFreinds">
                      <input
                        type="checkbox"
                        class="form-control"
                        id="freeForFreinds"
                        v-model="free_for_friend"
                        value="true"
                        @change="makeFree()"
                      />
                      Free For Friends
                    </label>
                  </div>
                </div>
                <div class="row justify-center">
                  <div class="col-6">
                    <label>Discount Amount</label>
                    <input
                      type="number"
                      v-model="discount_amount"
                      class="form-control"
                      name="discount_amount"
                      placeholder="₹ 0.00"
                      :disabled="free_for_friend"
                    />
                    <div class="error" v-if="owner_errors?.discount_amount">
                      {{ owner_errors?.discount_amount[0] }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-100 villa_card_owner_other_wrapper"
                v-if="
                  userProfile.user_type == 'Owner' &&
                  is_owner_other_booking_show
                "
              >
                <div class="row align-center">
                  <div class="col-6 justify-start">
                    <p class="price">
                      <span class="rupee">₹</span
                      >{{
                        parseFloat(
                          ownerCalc.final_total
                            ? ownerCalc.final_total -
                                (discount_amount ? discount_amount : 0)
                            : 0
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}.00
                    </p>
                  </div>
                  <div class="col-6 justify-end">
                    <button
                      class="main_button_user mt-0"
                      @click="ownerBook"
                      :disabled="disabled"
                    >
                      <span>Book Now</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            v-if="booking_type == 'OneDayPicnic' && form.picnic_package"
            class="counter_wrapper"
          >
            <div class="col-lg-12 pm_detail_col counter title">
              <div class="row num_slider px-1 w-100">
                <div class="col-lg-12 title text-center">
                  <label
                    class="form-label"
                    for="basic-addon-name"
                    v-if="booking_type == 'OneDayPicnic'"
                    >{{ form.picnic_package.pack_name }}</label
                  >
                </div>
              </div>
            </div>
            <div class="datePicker">
              <Datepicker
                type="date"
                :min="new Date().toISOString().substr(0, 10)"
                v-model="form.rangeDate"
                @closed="dateChange()"
                placeholder="Check In Check Out"
                format="dd-MM-yyyy"
                value-format="dd-MM-yyyy"
                auto-apply
              />
              <div
                class="invalid-feedback fw-bold fst-italic text-danger mt-1"
                :style="{ display: checkInDateError }"
              >
                Please choose check-in check-out date.
              </div>
            </div>
            <div class="col-lg-12 pm_detail_col counter mt-3">
              <div class="row num_slider mt-3 px-1 w-100 picnic_wrap">
                <div class="col-lg-4 title picnic_title">
                  <label class="form-label" for="basic-addon-name"
                    >Adults (12+ Years)</label
                  >
                </div>
                <div class="col-lg-4 title picnic_price">
                  <label class="form-label" for="basic-addon-name"
                    ><span class="rupee"
                      >₹{{
                        form.picnic_package.adult_price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}.00</span
                    ></label
                  >
                </div>
                <div class="col-lg-1 num_btn">
                  <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="adultDecrement"
                  >
                    -
                  </button>
                </div>
                <div class="col-lg-1 num_val">
                  {{ form.total_adult }}
                </div>
                <div class="col-lg-1 num_btn">
                  <button
                    type="button"
                    class="btn btn-sm btn-success"
                    @click="adultIncrement"
                  >
                    +
                  </button>
                </div>
              </div>
              <div
                class="row num_slider mt-1 px-1 w-100 picnic_wrap pmt"
                v-if="form.total_adult > 0"
              >
                <div class="col-lg-4 title picnic_title">
                  <label class="form-label" for="basic-addon-name"
                    >Childs (5 - 12 Years)</label
                  >
                </div>
                <div class="col-lg-4 title picnic_price">
                  <label class="form-label" for="basic-addon-name"
                    ><span class="rupee"
                      >₹{{
                        form.picnic_package?.child_price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}.00</span
                    ></label
                  >
                </div>
                <div class="col-lg-1 num_btn">
                  <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="childDecrement"
                  >
                    -
                  </button>
                </div>
                <div class="col-lg-1 num_val">
                  {{ form.total_child }}
                </div>
                <div class="col-lg-1 num_btn">
                  <button
                    type="button"
                    class="btn btn-sm btn-success"
                    @click="childIncrement"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li
            v-if="booking_type == 'EventBooking' && form.event"
            class="counter_wrapper"
          >
            <div class="col-lg-12 pm_detail_col counter title">
              <div class="row num_slider mt-3 px-1 w-100">
                <div class="col-lg-12 title text-center">
                  <label class="form-label" for="basic-addon-name">{{
                    form.event.name
                  }}</label>
                </div>
              </div>
            </div>
            <div class="col-lg-12 pm_detail_col counter">
              <div class="row num_slider mt-3 px-1 w-100">
                <div class="col-lg-4 title">
                  <label class="form-label" for="basic-addon-name"
                    >Adults (12+ Years)</label
                  >
                </div>
                <div class="col-lg-4 title">
                  <label class="form-label" for="basic-addon-name"
                    ><span class="rupee"
                      >₹{{
                        form.event.adult_price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}.00</span
                    ></label
                  >
                </div>
                <div class="col-lg-1 num_btn">
                  <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="adultDecrement"
                  >
                    -
                  </button>
                </div>
                <div class="col-lg-1 num_val">
                  {{ form.total_adult }}
                </div>
                <div class="col-lg-1 num_btn">
                  <button
                    type="button"
                    class="btn btn-sm btn-success"
                    @click="adultIncrement"
                  >
                    +
                  </button>
                </div>
              </div>
              <div
                class="row num_slider mt-1 px-1 w-100"
                v-if="form.total_adult > 0"
              >
                <div class="col-lg-4 title">
                  <label class="form-label" for="basic-addon-name"
                    >Childs (5 - 12 Years)</label
                  >
                </div>
                <div class="col-lg-4 title">
                  <label class="form-label" for="basic-addon-name"
                    ><span class="rupee"
                      >₹{{
                        form.event?.child_price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}.00</span
                    ></label
                  >
                </div>
                <div class="col-lg-1 num_btn">
                  <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="childDecrement"
                  >
                    -
                  </button>
                </div>
                <div class="col-lg-1 num_val">
                  {{ form.total_child }}
                </div>
                <div class="col-lg-1 num_btn">
                  <button
                    type="button"
                    class="btn btn-sm btn-success"
                    @click="childIncrement"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li
            class="primary_mbm_detail_block"
            v-if="
              bookingResult.person &&
              form.rangeDate &&
              is_owner_other_booking_show == false
            "
          >
            <div class="pm_detail_block">
              <div
                data-repeater-list="group-a"
                style="margin-bottom: 50px; margin-top: 50px"
              >
                <div v-for="(item, index) in form.persons" :key="item">
                  <div
                    :class="
                      index === 0
                        ? `border-bottom sectionBlock mb-1 pt-1 pb-1`
                        : index === 1
                        ? `selectioBoxOther pt-1`
                        : ``
                    "
                    class="d-flex row memeber_list"
                  >
                    <div
                      class="float-left green_font type_title"
                      v-show="index === 0"
                    >
                      <div class="col-12">
                        <span>Primary Member Details</span>
                      </div>
                    </div>

                    <div
                      class="float-left green_font type_title"
                      v-show="index === 1"
                    >
                      <div class="col-12">
                        <span>Other Member Details</span>
                      </div>
                    </div>

                    <input
                      type="hidden"
                      name="detail_id"
                      :id="`detail_id` + index"
                      v-model="form.persons[index].detail_id"
                    />
                    <input
                      type="hidden"
                      name="user_id"
                      :id="`user_id` + index"
                      v-model="form.persons[index].user_id"
                    />
                    <input
                      type="hidden"
                      name="barcode_id"
                      :id="`barcode_id` + index"
                      v-model="form.persons[index].barcode_id"
                    />

                    <div class="pm_detail_form">
                      <div
                        :class="
                          index == 0
                            ? `pm_detail_col`
                            : `pm_detail_col other_member_detail`
                        "
                      >
                        <div class="pm_detail_12">
                          <div class="pm_detail_lt">
                            <div class="pm_deatil_icon">
                              <img
                                src="../../assets/images/User/user-icon.png"
                              />
                            </div>
                          </div>
                          <div class="pm_detail_rt">
                            <div class="pm_deatil_in">
                              <label class="pm_label"
                                >First Name<span style="color: red"
                                  >*</span
                                ></label
                              >
                              <input
                                type="text"
                                class="pm_input"
                                name="first_name"
                                v-model="form.persons[index].first_name"
                              />
                              <div
                                class="fw-bold fst-italic error"
                                v-show="firstError[index]"
                              >
                                {{ firstError[index] }}
                              </div>
                            </div>
                            <div class="pm_deatil_in">
                              <label class="pm_label">Middle Name</label>
                              <input
                                type="text"
                                class="pm_input"
                                name="middle_name"
                                v-model="form.persons[index].middle_name"
                              />
                            </div>
                            <div class="pm_deatil_in">
                              <label class="pm_label"
                                >Last Name<span style="color: red"
                                  >*</span
                                ></label
                              >
                              <input
                                type="text"
                                class="pm_input"
                                name="last_name"
                                v-model="form.persons[index].last_name"
                              />
                              <div
                                class="error fw-bold fst-italic"
                                v-show="lastError[index]"
                              >
                                {{ lastError[index] }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pm_detail_12">
                          <div class="pm_detail_lt">
                            <div class="pm_deatil_icon">
                              <img
                                src="../../assets/images/User/call_icon.png"
                              />
                            </div>
                          </div>
                          <div class="pm_detail_rt">
                            <div class="pm_deatil_in">
                              <label class="pm_label"
                                >Phone Number<span style="color: red"
                                  >*</span
                                ></label
                              >
                              <input
                                type="number"
                                class="pm_input"
                                name="mobile"
                                v-model="form.persons[index].mobile"
                                id="mobile"
                              />
                              <div
                                class="error fw-bold fst-italic"
                                v-show="mobileError[index]"
                              >
                                {{ mobileError[index] }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pm_detail_12">
                          <div class="pm_deatil_in" style="display: flex">
                            <div v-show="index === 0">
                              <label
                                :for="`files${index}`"
                                class="main_button_user_square"
                                ><img
                                  src="../../assets/images/User/file-text white.png"
                                  alt="document"
                              /></label>
                              <input
                                type="file"
                                name="id_proof"
                                ref="proofFile"
                                :id="`files${index}`"
                                @change="uploadProofImage(index, $event)"
                                accept="image/png, image/gif, image/jpeg"
                                style="display: none"
                              />
                            </div>
                            <div v-if="form.persons[index].id_tmp_proof" >
                              <img
                                :src="form.persons[index].id_tmp_proof"
                                class="rounded"
                                height="50"
                                width="50"
                                style="margin-left: 10px" v-show="index === 0"
                              />
                            </div>
                            <div
                              v-else 
                              v-bind:class="[index === 0 ? 'imgContainer' : 'imgContainer-zero']"
                            ></div>
                            <div class="d-flex trash" v-if="index !== 0" @click="removeContent(index)">
                              <span>
                                <img
                                  src="../../assets/images/User/Trash_2.png"
                                  alt="delete"
                                />
                              </span>
                            </div>
                            <div class="d-flex trash disbaled" v-else>
                              <span>
                                <img
                                  src="../../assets/images/User/Trash_2.png"
                                  alt="delete"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="pm_detail_col addBtn"
                        style="border: none; padding: 0"
                        v-show="bookingResult.person > form.persons.length"
                      >
                        <div class="col-12">
                          <div
                            class="pm_detail_rt"
                            style="justify-content: end"
                            v-if="index >= parseInt(form.persons.length - 1)"
                          >
                            <div class="add_member">
                              <button
                                type="button"
                                class="main_button_user_square_plus"
                                @click="addToContent()"
                                data-repeater-create
                              >
                                <span class="plus">+</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="main_reserves_row new_bottom"
        :class="villaList.length == 0 ? `` : `main_reserves_row_new`"
        v-if="
          bookingResult.person &&
          form.rangeDate &&
          is_owner_other_booking_show == false
        "
      >
        <div class="check_inr_right">
          <ul class="check_rt_ul">
            <li
              class="check_rt_li"
              v-if="booking_type == 'VillaBooking' && bookingResult.person"
            >
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Person</span>
                </div>
                <div class="check_numver">
                  <span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                        fill="#153936"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                        fill="#153936"
                      />
                    </svg>
                    x {{ bookingResult ? bookingResult.person : 0 }}
                  </span>
                </div>
              </div>
            </li>
            <li
              class="check_rt_li"
              v-if="booking_type == 'VillaBooking' && form.days"
            >
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Days</span>
                </div>
                <div class="check_numver">
                  <span>{{ form.days }}</span>
                </div>
              </div>
            </li>
            <li class="check_rt_li" v-if="booking_type == 'VillaBooking'">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Villa Price</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(showCalc.total ? showCalc.total : 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <li
              class="check_rt_li"
              v-if="['OneDayPicnic', 'EventBooking'].includes(booking_type)"
            >
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Total Adults Price</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(
                        showCalc.total_adult_price
                          ? showCalc.total_adult_price
                          : 0
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <li
              class="check_rt_li"
              v-if="['OneDayPicnic', 'EventBooking'].includes(booking_type)"
            >
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Total Childs Price</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(
                        showCalc.total_child_price
                          ? showCalc.total_child_price
                          : 0
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <li class="check_rt_li" v-if="showCalc.extra_person_charge">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Extra Person</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(
                        showCalc.extra_person_charge
                          ? showCalc.extra_person_charge
                          : 0
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <li class="check_rt_li" v-if="showCalc.festival_charge">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Festival Charge</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(
                        showCalc.festival_charge ? showCalc.festival_charge : 0
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <li class="check_rt_li">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Sub Total</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(showCalc.sub_total ? showCalc.sub_total : 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <li class="check_rt_li" v-if="form.coupon_code">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Discount</span>
                </div>
                <div class="check_numver text-danger">
                  <span
                    >- <span class="rupee">₹</span
                    >{{
                      parseFloat(
                        showCalc.coupon_amount ? showCalc.coupon_amount : 0
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <li class="check_rt_li">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>(18%) GST INC.</span>
                </div>
              </div>
            </li>
          </ul>
          <div class="check_rt_div total_price_col main_button_user_custom">
            <div class="check_rt_div">
              <div class="check_text">
                <span>Grand Total</span>
              </div>
              <div class="check_numver">
                <p class="regu_price">
                  <span class="rupee">₹</span
                  >{{
                    parseFloat(showCalc.final_total ? showCalc.final_total : 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}.00
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="main_reserves_col main_button_wrapper"
          v-if="bookingResult.total && bookingResult.person"
        >
          <!-- <span
            class="offercodelink"
            @click="offerCodeToggle"
            v-if="!coupon_applied"
            >GOT AN OFFER/PROMO CODE?</span
          >
          <div
            class="offercodewrapper"
            v-if="offerCodeInputOpen && coupon_applied == false"
          >
            <input
              type="text"
              class="form-control"
              v-model="form.coupon_code"
            />
            <button type="submit" class="offer_submit" @click="addPromoCode">
              Apply
            </button>
          </div> -->
          <button
            class="main_button_user"
            type="submit"
            :disabled="disabled"
            @click="book"
          >
            <span>Book Now</span>
          </button>
        </div>
      </div>
    </div>
  </MasterLayout>
</template>
<script>
import Form from "vform";
import { ref } from "vue";
import MasterLayout from "./MasterLayout.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import axios from "axios";
// import { mapGetters, mapActions } from "vuex";
// import { isProxy, toRaw } from "vue";

import banner02 from "../../assets/images/User/banner02.jpg";
import frame from "../../assets/images/User/Frame.png";

export default {
  name: "Dashboard",
  components: { MasterLayout, Datepicker },
  data() {
    return {
      frame: frame,
      banner02: banner02,
      main_name: "Dashboard",
      link: [{ link: "home", name: "Home" }],
      token: localStorage.getItem("auth_cus_token"),
      booking_type: null,
      userProfile: null,
      form: new Form({
        rangeDate: ref(),
        check_in_date: "",
        check_out_date: "",
        days: 0,
        person: parseInt(0),
        persons: [
          {
            detail_id: "",
            user_id: "",
            barcode_id: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            mobile: "",
            id_proof: "",
            id_tmp_proof: "",
            is_primary: true,
            user_type: "Guest",
            villa_id: null,
          },
        ],
        villa_ids: [],
        total: 0,
        gst_tax: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax_percent: 0,
        payment_mode_id: 2,
        coupon_code: null,
        coupon_amount: 0,
        picnic_package: null,
        event: null,
        total_adult: this.$route.query.adults
          ? parseInt(this.$route.query.adults)
          : 0,
        total_child: this.$route.query.childs
          ? parseInt(this.$route.query.childs)
          : 0,
      }),
      is_choose_date: false,
      is_owner_villa_avilable: false,
      is_owner_other_booking_show: false,
      bookingResult: {
        total: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        gst_tax_percent: 0,
        person: 0,
      },
      villaList: [],
      villa_booking: [],
      villa_type: {
        platinum: {
          qty: 0,
          person: 0,
        },
        diamond: {
          qty: 0,
          person: 0,
        },
        cottages: {
          qty: 0,
          person: 0,
        },
      },
      clickVillaId: [],
      reserve_error: {
        platinum: {
          error: null,
        },
        diamond: {
          error: null,
        },
        cottages: {
          error: null,
        },
      },
      checkInDateError: "none",
      firstError: [],
      lastError: [],
      mobileError: [],
      owner_errors: {},
      payurl: null,
      disabled: false,
      loader: false,
      total_child: 0,
      discount_amount: null,
      free_for_friend: false,
      dashboardBooking: [],
      offerCodeInputOpen: false,
      coupon_applied: false,
    };
  },
  beforeCreate() {
    this.booking_type = null;
  },
  created() {
    this.booking_type = this.$route.params.bookingtype
      ? this.$route.params.bookingtype
      : null;
    if (this.booking_type == "VillaBooking") {
      this.is_choose_date = true;
    }
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!this.token) {
      this.$router.push({ name: "home" });
      window.location.href = "/";
    }
    this.getUserProfile();
    if (this.booking_type == "OneDayPicnic") {
      this.getPicnicPackageDetail(
        this.$route.query.package_id ? this.$route.query.package_id : null
      );
      this.form.person = parseInt(this.form.total_adult);
      this.bookingResult.person = parseInt(this.form.total_adult);
      this.form.rangeDate = this.$route.query.date
        ? moment.utc(this.$route.query.date)._d
        : ref();
      this.form.check_in_date = this.$route.query.date
        ? this.$route.query.date
        : "";
      this.form.check_out_date = this.$route.query.date
        ? this.$route.query.date
        : "";
      this.checkAvailability();
    } else if (this.booking_type == "EventBooking") {
      this.getEventDetail(
        this.$route.query.event_id ? this.$route.query.event_id : null
      );
      this.form.person = parseInt(this.form.total_adult);
      this.bookingResult.person = parseInt(this.form.total_adult);
      this.form.rangeDate = this.$route.query.date
        ? moment.utc(this.$route.query.date)._d
        : ref();
      this.form.check_in_date = this.$route.query.date
        ? this.$route.query.date
        : "";
      this.form.check_out_date = this.$route.query.date
        ? this.$route.query.date
        : "";
      this.checkAvailability();
    } else if (this.booking_type == "VillaBooking") {
      this.booking_type = "VillaBooking";
    } else {
      this.getBookingData();
    }
  },
  computed: {
    showCalc: function () {
      const price = parseFloat(
        this.bookingResult.total -
          this.bookingResult.festival_charge -
          this.bookingResult.extra_person_charge -
          this.bookingResult.gst_tax
      );
      const festival_charge = this.bookingResult.festival_charge;
      const extra_person_charge = this.bookingResult.extra_person_charge;
      const sub_total =
        parseFloat(price) +
        parseFloat(festival_charge) +
        parseFloat(extra_person_charge);
      const coupon_amount = this.form.coupon_amount;

      var total_adult_price = 0;
      var total_child_price = 0;
      console.log(this.booking_type);
      if (this.booking_type && this.booking_type == "OneDayPicnic") {
        total_adult_price =
          this.form.total_adult * this.form.picnic_package.adult_price;
        total_child_price =
          this.form.total_child * this.form.picnic_package.child_price;
      } else if (this.booking_type && this.booking_type == "EventBooking") {
        total_adult_price = this.form.total_adult * this.form.event.adult_price;
        total_child_price = this.form.total_child * this.form.event.child_price;
      }

      return {
        total: price,
        festival_charge: festival_charge,
        extra_person_charge: extra_person_charge,
        total_adult_price: total_adult_price,
        total_child_price: total_child_price,
        sub_total: sub_total,
        coupon_amount: coupon_amount,
        gst_tax: sub_total - sub_total * (100 / (100 + 18)),
        gst_tax_percent: this.bookingResult.gst_tax_percent,
        final_total: parseInt(this.bookingResult.total - coupon_amount),
      };
    },
    ownerCalc: function () {
      const villa_price = parseFloat(
        this.bookingResult.total -
          this.bookingResult.festival_charge -
          this.bookingResult.extra_person_charge -
          this.bookingResult.gst_tax
      );

      const festival_charge = this.bookingResult.festival_charge;
      const extra_person_charge = this.bookingResult.extra_person_charge;

      return {
        total: villa_price,
        festival_charge: festival_charge,
        extra_person_charge: extra_person_charge,
        sub_total: villa_price + festival_charge + extra_person_charge,
        gst_tax: this.bookingResult.gst_tax,
        gst_tax_percent: this.bookingResult.gst_tax_percent,
        final_total: this.bookingResult.total,
      };
    },
  },
  methods: {
    async getBookingData() {
      this.loader = true;
      await axios
        .get(process.env.VUE_APP_API_CUS_URL + "/dashboard", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.dashboardBooking = response.data.data;
          localStorage.removeItem("id");
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    addToContent() {
      this.form.persons.push({
        detail_id: "",
        user_id: "",
        barcode_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        mobile: "",
        id_proof: "",
        id_tmp_proof: "",
        is_primary: false,
        user_type: "Guest",
      });
    },
    uploadProofImage(index, e) {
      let imgProof = e.target.files[0];
      if (imgProof.size / 1024 >= 7000) {
        e.target.value = null;
        this.$toast.error("File too Big, please select a file less than 400Kb");
        return false;
      }
      this.createBase64Image(imgProof, index);
    },
    createBase64Image(fileObject, index) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // const newImageEncode = e.target.result.split("base64,");
        // this.form.persons[index].id_tmp_proof = e.target.result;
        // this.form.persons[index].id_proof = newImageEncode[1];
        this.form.persons[index].id_tmp_proof = e.target.result;
        this.form.persons[index].id_proof = fileObject;
      };
      reader.readAsDataURL(fileObject);
    },
    removeContent(idx) {
      this.form.persons.splice(idx, 1);
    },
    async getUserProfile() {
      this.loader = true;
      await axios
        .get(process.env.VUE_APP_API_CUS_URL + "/user-profile", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.userProfile = response.data.data;
          if (
            response.data.data.bookings_panding_by_owners.length > 0 &&
            this.$router.options.history.state.back == "/"
          ) {
            this.$router.push({ name: "booking-pending" });
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");
            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    dateResult() {
      const date = this.form.rangeDate;
      if (date.length > 0 && date.length != undefined) {
        date.map(() => {
          const start_date = moment(date[0]).format("YYYY-MM-DD");
          const end_date = moment(date[1]).format("YYYY-MM-DD");
          this.form.check_in_date = start_date;
          this.form.check_out_date = end_date;

          const dateA = moment(end_date);
          const dateB = moment(start_date);
          this.form.days = dateA.diff(dateB, "days");
        });
      } else {
        if (typeof date != undefined) {
          this.form.check_in_date = moment(date).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date).format("YYYY-MM-DD");
          this.form.days = 1;
        }
      }
    },
    dateChange() {
      if (this.booking_type == "OneDayPicnic") {
        this.dateResult();
        this.checkAvailability();
      }else if (this.booking_type == "EventBooking") {
        this.dateResult();
        this.checkAvailability();
      } else {
        this.dateResult();
        if (this.form.rangeDate.length > 0) {
          this.checkInDateError = "none";
        }

        if (this.userProfile.user_type == "Guest") {
          axios
            .post(
              process.env.VUE_APP_API_CUS_URL + "/search-villa",
              {
                check_in_date: this.form.check_in_date,
                check_out_date: this.form.check_out_date,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${this.token}`,
                },
              }
            )
            .then((response) => {
              this.bookingResult = {
                total: 0,
                extra_person_charge: 0,
                festival_charge: 0,
                gst_tax: 0,
                gst_tax_percent: 0,
                person: 0,
              };
              this.villaList = [];
              this.villaList = response.data.data;
              if (this.villaList) {
                this.villa_booking = [];
                for (var i = 0; i <= this.villaList.length; i++) {
                  if (this.villaList[i] && this.villaList[i].villa_name) {
                    var vType = this.villaList[i].villa_name.split(" ");
                    if (vType) {
                      this.villaList[i].pack_type = vType[0].toLowerCase();
                      this.villa_type[vType[0].toLowerCase()] = {
                        qty: 0,
                        person: 0,
                      };
                    }

                    this.villa_booking.push({
                      villa_type_id: this.villaList[i].villaTypeId,
                      quantity: this.villa_type[vType[0].toLowerCase()].qty,
                      person: this.villa_type[vType[0].toLowerCase()].person,
                      villa_name: this.villaList[i].villa_name,
                      check_in_date: this.form.check_in_date,
                      check_out_date: this.form.check_out_date,
                    });
                  }
                }
              }
            })
            .catch((e) => {
              console.log("e: ", e);
              if (e.response.status == 401) {
                localStorage.removeItem("auth_otp");
                localStorage.removeItem("auth_user_type");
                localStorage.removeItem("auth_cus_token");

                this.removeLocalstorage();

                this.$router.push({ name: "home" });
                window.location.href = "/";
              }
            });
        } else if (this.userProfile.user_type == "Owner") {
          this.is_owner_villa_avilable = false;
          this.is_owner_other_booking_show = false;
          this.villa_booking = [];
          axios
            .post(
              process.env.VUE_APP_API_URL + "/owner/book-myself",
              {
                check_in_date: this.form.check_in_date,
                check_out_date: this.form.check_out_date,
                villa_type_id: this.userProfile.owner_details.villa_type_id,
                villa_id: this.userProfile.owner_details.villa_id,
                check_type: "Owner",
                temp: true,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${this.token}`,
                },
              }
            )
            .then((response) => {
              if (response.data.code == 200) {
                this.is_owner_villa_avilable = true;
              }
              if (response.data.code == 404) {
                this.$toast.error('Booking not available on this date.');
              }

              this.villa_booking.push({
                villa_type_id: this.userProfile.owner_details.villa_type_id,
                quantity: 1,
                person: 1,
                villa_name: this.userProfile.owner_details.villa_name,
                check_in_date: this.form.check_in_date,
                check_out_date: this.form.check_out_date,
              });

              this.form.persons[0].villa_id =
                this.userProfile?.owner_details?.villa_id;

              this.bookingResult = {
                total: 0,
                extra_person_charge: 0,
                festival_charge: 0,
                gst_tax: 0,
                gst_tax_percent: 0,
                person: 0,
              };
            })
            .catch((e) => {
              console.log("e: ", e);
              if (e.response?.status == 401) {
                localStorage.removeItem("auth_otp");
                localStorage.removeItem("auth_user_type");
                localStorage.removeItem("auth_cus_token");

                this.$router.push({ name: "home" });
                window.location.href = "/";
              }
            });
        }
      }
    },
    async checkAvailability() {

      await axios
        .post(
          process.env.VUE_APP_API_URL + "/check-availability",
          {
            booking_type: this.booking_type,
            from_date: this.form.check_in_date,
            to_date: this.form.check_out_date,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {

          var $this = this;
          
          response.data.data.forEach(function(item){

            if(item.booking_type == 'PicnicBooking'){

                if(moment(item.date).format("YYYY-MM-DD") == $this.form.check_in_date){
                  if(item.for_picnic == 'All' || parseInt(item.for_picnic) === parseInt($this.$route.query.package_id)){
                    $this.$toast.error("Booking not available on this date.");
                    $this.form.rangeDate = ref();
                    $this.form.check_in_date = "";
                    $this.form.check_out_date = "";
                    return false;
                  }
                }

            }else if(item.booking_type == 'EventBooking'){

                if(moment(item.date).format("YYYY-MM-DD") == $this.form.check_in_date){
                  if(item.for_event == 'All' || parseInt(item.for_event) === parseInt($this.$route.query.event_id)){
                    $this.$toast.error("Booking not available on this date.");
                    $this.form.rangeDate = ref();
                    $this.form.check_in_date = "";
                    $this.form.check_out_date = "";
                    return false;
                  }
                }
            }

          });
          
        })
        .catch((e) => {
          console.log("e: ", e);
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    villaClick(villa_id) {
      var chek = this.clickVillaId.find((c) => c == villa_id);
      if (chek) {
        console.log(chek);
      } else {
        this.clickVillaId.push(villa_id);
      }
    },
    onChangeVillaQty(villa_id, qty, person) {
      for (let [inQ, villaQ] of this.villa_booking.entries()) {
        if (this.villa_booking[inQ].villa_type_id == villa_id) {
          if (this.form.villa_ids.includes(villaQ.villa_type_id)) {
            console.log("villaQ: ", villaQ.villa_type_id);
          } else {
            this.form.villa_ids.push(villaQ.villa_type_id);
          }

          if (qty == 0) {
            var vType = villaQ.villa_name.split(" ");
            this.villa_type[vType[0].toLowerCase()] = {
              person: 0,
              qty: 0,
            };
          }

          this.villa_booking[inQ] = {
            villa_type_id: villaQ.villa_type_id,
            quantity:
              qty == 0
                ? parseInt(qty)
                : parseInt(qty.split("_") ? qty.split("_")[1] : 0),
            person:
              qty == 0
                ? 0
                : person
                ? parseInt(person.split("_") ? person.split("_")[1] : 0)
                : 0,
            villa_name: villaQ.villa_name,
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
          };
          this.villaBookingTotal();
        }
      }
    },
    async villaBookingTotal() {
      axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/villa-booking-total",
          {
            villa_booking: this.villa_booking,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.bookingResult = response.data.data;
          this.villa_price = this.bookingResult.total;
          if (this.bookingResult) {
            this.bookingResult.gst_tax_percent = this.bookingResult.gst_tax
              ? this.bookingResult.gst_tax
              : 0;

            this.bookingResult.total =
              parseInt(this.bookingResult.total) +
              parseInt(this.bookingResult.extra_person_charge) +
              parseInt(this.bookingResult.festival_charge);

            this.bookingResult.gst_tax =
              (this.bookingResult.total * this.bookingResult.gst_tax_percent) /
              100;

            this.bookingResult.total =
              this.bookingResult.total + this.bookingResult.gst_tax;
          }
          console.log("response: ", this.bookingResult);
        })
        .catch((e) => {
          console.log("e: ", e);
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    book() {
      if (this.booking_type == "VillaBooking") {
        this.villaBooking();
      } else {
        this.bookNow();
      }
    },
    villaBooking() {
      var reserve_error_count = 0;
      this.villa_booking.forEach((element) => {
        var vType = element.villa_name.split(" ");
        if (element.quantity > 0 && element.person == 0) {
          this.reserve_error[vType[0].toLowerCase()] = {
            error: "Quanitity of persons are must be select!",
          };
          reserve_error_count++;
        } else {
          this.reserve_error[vType[0].toLowerCase()] = {
            error: "",
          };
        }
      });

      if (reserve_error_count == 0) {
        this.bookNow();
      }
    },
    async bookNow() {
      if (this.form.persons.length > this.bookingResult.person) {
        this.$toast.error("Please remove extra members.");
        return false;
      }

      this.firstError = [];
      this.lastError = [];
      this.mobileError = [];
      for (var i = 0; i < this.form.persons.length; i++) {
        if (
          this.form.persons[i].first_name == undefined ||
          this.form.persons[i].first_name == null ||
          this.form.persons[i].first_name == ""
        ) {
          this.firstError[i] = "Please enter first name.";
          return;
        }
        if (
          this.form.persons[i].last_name == undefined ||
          this.form.persons[i].last_name == null ||
          this.form.persons[i].last_name == ""
        ) {
          this.lastError[i] = "Please enter last name.";
          return;
        }

        if (
          this.form.persons[i].mobile == undefined ||
          this.form.persons[i].mobile == null ||
          this.form.persons[i].mobile == ""
        ) {
          this.mobileError[i] = "Please enter mobile no.";
          return;
        }

        if (this.form.persons[i].mobile.toString().length != 10) {
          this.mobileError[i] = "Please enter valid mobile no.";
          return;
        }
      }

      var mobiles = this.form.persons.map(({ mobile }) => mobile);

      if (
        mobiles.filter((item, index) => mobiles.indexOf(item) !== index)
          .length > 0
      ) {
        this.$toast.error("Please enter different phone no. in each person.");
        return false;
      }

      if (this.booking_type != "OneDayPicnic") {

        if (this.form.persons.length != this.bookingResult.person) {
          this.$toast.error("Please add all members details.");
          return false;
        }

      }

      this.disabled = true;

      var apiUrl = process.env.VUE_APP_API_CUS_URL + "/villa-book";


      var newPersons = [];

      this.form.persons.forEach((element, index) => {
        delete element.id_tmp_proof;
        element.is_primary = element.is_primary == true ? 1 : 0;
        newPersons[index] = element;
      });

      var payload = {
        check_in_date: this.form.check_in_date,
        check_out_date: this.form.check_out_date,
        total_child: this.form.total_child,
        coupon_code: this.form.coupon_code,
        coupon_amount: this.form.coupon_amount,
        payment_mode_id: this.form.payment_mode_id,
        total: this.showCalc.total,
        extra_person_charge: this.showCalc.extra_person_charge,
        festival_charge: this.showCalc.festival_charge,
        gst_tax: this.showCalc.gst_tax_percent,
        gst_amount: 0,
        person: this.bookingResult.person,
        sub_total: this.showCalc.sub_total,
        final_total: this.showCalc.final_total,
        persons: newPersons,
      };
      if (this.booking_type == "VillaBooking") {
        payload.type = null;
        payload.villa_booking = this.villa_booking;
        payload.id = null;
      } else {
        payload.type = this.booking_type;
        if (this.booking_type == "OneDayPicnic") {
          payload.villa_booking = [
            {
              villa_type_id: this.form.picnic_package.id
                ? this.form.picnic_package.id
                : null,
              person: this.bookingResult.person,
            },
          ];
          payload.id = this.form.picnic_package.id
            ? this.form.picnic_package.id
            : null;
        } else if (this.booking_type == "EventBooking") {
          payload.villa_booking = [
            {
              villa_type_id: this.form.event.id ? this.form.event.id : null,
              person: this.bookingResult.person,
            },
          ];
          payload.id = this.form.event.id ? this.form.event.id : null;
        }
      }


      // const newformData = new FormData();
      // newformData.append('persons', this.form.persons[0]['id_proof']);

      await axios
        .post(apiUrl, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.payurl = null;

            if (this.form.payment_mode_id == 1) {
              this.$toast.success(response.data?.message);
              this.$router.push({ name: "thanks" });
            } else {
              this.getPayLink(response.data.data.booking_id);
            }
          } else {
            this.$toast.error(response.data?.message);
          }

          setTimeout(() => {
            this.disabled = false;
          }, 500);
        });
    },
    async getPayLink(booking_id) {
      this.payurl = null;

      const formdata = new FormData();
      formdata.append("amount", this.bookingResult.total);
      formdata.append("user_id", this.userProfile.id);
      formdata.append("device", "web");
      formdata.append("mobileNumber", this.userProfile.mobile);
      formdata.append("redirectMode", "REDIRECT");
      formdata.append("booking_id", booking_id);

      await axios
        .post(process.env.VUE_APP_API_CUS_URL + "/pay-online", formdata, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          if (response.data.links) {
            this.payurl = response.data.links.pay_link_web;
            window.location.href = response.data.links.pay_link_web;
          }
        });
    },
    // async getPackageDetail() {
    //   this.loader = true;
    //   await axios
    //     .get(
    //       process.env.VUE_APP_API_CUS_URL +
    //         "/get-events/" +
    //         this.$route.params.event_id,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Accept: "application/json",
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       this.loader = false;
    //       if (response.data.code == 200) {
    //         this.form.package = response.data.data;
    //         this.form.package.gst = 0;
    //         const dateArr = [];
    //         if (response.data.data.from_date) {
    //           this.form.check_in_date = response.data.data.from_date;
    //           dateArr.push(moment.utc(response.data.data.from_date)._d);
    //         }
    //         if (response.data.data.to_date) {
    //           this.form.check_out_date = response.data.data.to_date;
    //           dateArr.push(moment.utc(response.data.data.to_date)._d);
    //         }
    //         if (response.data.data.from_date != response.data.data.to_date) {
    //           this.form.rangeDate = dateArr;
    //         } else {
    //           this.form.rangeDate = moment.utc(response.data.data.from_date)._d;
    //         }
    //       } else {
    //         this.$toast.error(response.data?.message);
    //       }
    //     });
    // },
    async getPicnicPackageDetail(package_id) {
      if (package_id) {
        this.loader = true;
        await axios
          .get(process.env.VUE_APP_API_URL + "/packages/" + package_id, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            this.loader = false;
            if (response.data.code == 200) {
              this.form.picnic_package = response.data.data;
              this.getTotalPrice();
            } else {
              this.$toast.error(response.data?.message);
            }
          });
      }
    },
    async getEventDetail(event_id) {
      if (event_id) {
        this.loader = true;
        await axios
          .get(process.env.VUE_APP_API_CUS_URL + "/get-events/" + event_id, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            this.loader = false;
            if (response.data.code == 200) {
              this.form.event = response.data.data;
              this.getTotalPrice();
            } else {
              this.$toast.error(response.data?.message);
            }
          });
      }
    },
    adultIncrement() {
      this.form.total_adult++;
      this.totalPerson();
      this.getTotalPrice();
    },
    adultDecrement() {
      if (this.form.total_adult > 1) {
        this.form.total_adult--;
      }
      this.totalPerson();
      this.getTotalPrice();
    },
    childIncrement() {
      this.form.total_child++;
      this.getTotalPrice();
    },
    childDecrement() {
      if (this.form.total_child > 0) {
        this.form.total_child--;
        this.getTotalPrice();
      }
    },
    totalPerson() {
      this.bookingResult.person = parseInt(this.form.total_adult);
      this.form.person = parseInt(this.form.total_adult);
    },
    getTotalPrice() {
      var adult_price = 0;
      var child_price = 0;
      if (this.booking_type == "OneDayPicnic") {
        adult_price = this.form.picnic_package.adult_price
          ? parseFloat(this.form.picnic_package.adult_price)
          : parseFloat(0);
        child_price = this.form.picnic_package.child_price
          ? parseFloat(this.form.picnic_package.child_price)
          : parseFloat(0);
      } else if (this.booking_type == "EventBooking") {
        adult_price = this.form.event.adult_price
          ? parseFloat(this.form.event.adult_price)
          : parseFloat(0);
        child_price = this.form.event.child_price
          ? parseFloat(this.form.event.child_price)
          : parseFloat(0);
      }

      var total_price = 0;
      total_price += adult_price * parseInt(this.form.total_adult);
      total_price += child_price * parseInt(this.form.total_child);

      this.bookingResult.total = total_price;
    },
    ownerSelfBooking() {
      this.disabled = true;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/owner/book-myself",
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
            villa_type_id: this.userProfile.owner_details.villa_type_id,
            villa_id: this.userProfile.owner_details.villa_id,
            check_type: "Owner",
            temp: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$toast.success("Your villa has been booked successfully.");
            this.$router.push({ name: "thanks" });
          }
        })
        .catch((e) => {
          this.disabled = false;
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    selectAdults($events) {
      this.villa_booking[0].person = parseInt($events.target.value);
      this.villaBookingTotal();
    },
    makeFree() {
      this.discount_amount = null;
      if (this.free_for_friend === true) {
        this.discount_amount = this.ownerCalc.final_total;
      }
    },
    ownerOtherBookingShow() {
      this.is_owner_other_booking_show = true;
      this.is_owner_villa_avilable = false;
      this.villaBookingTotal();
    },
    ownerBook() {
      this.owner_errors = {};

      if (!this.form.persons[0].first_name) {
        this.owner_errors["first_name"] = ["Please enter first name."];
        return false;
      } else if (!this.form.persons[0].last_name) {
        this.owner_errors["last_name"] = ["Please enter last name."];
        return false;
      } else if (!this.form.persons[0].mobile) {
        this.owner_errors["phone_number"] = ["Please enter phone number."];
        return false;
      } else if (!this.villa_booking[0].person) {
        this.owner_errors["adults"] = ["Please select adults."];
        return false;
      } else if (this.ownerCalc.total < this.discount_amount) {
        this.owner_errors["discount_amount"] = ["Please enter valid amount."];
        return false;
      }

      this.disabled = true;

      const payload = {
        check_in_date: this.form.check_in_date,
        check_out_date: this.form.check_out_date,
        total_child: this.total_child,
        coupon_code: "",
        coupon_amount: this.discount_amount,
        payment_mode_id: 1,
        total: this.ownerCalc.total,
        extra_person_charge: this.ownerCalc.extra_person_charge,
        festival_charge: this.ownerCalc.festival_charge,
        gst_tax: this.ownerCalc.gst_tax_percent,
        gst_amount: this.ownerCalc.gst_tax,
        person: parseInt(this.villa_booking[0].person),
        sub_total: this.ownerCalc.sub_total - this.discount_amount,
        final_total: this.ownerCalc.final_total - this.discount_amount,
        owner_reference: 1,
        owner_by_discount: this.discount_amount ? 1 : 0,
        owner_id: this.userProfile.id,
        villa_booking: this.villa_booking,
        persons: this.form.persons,
        free_for_friend: this.free_for_friend,
      };

      axios
        .post(process.env.VUE_APP_API_URL + "/owner/other-booking", payload, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.disabled = false;
          if (response.data.code == 200) {
            this.$router.push({ name: "thanks" });
          }
        })
        .catch((e) => {
          console.log("e: ", e);
          this.disabled = false;
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    offerCodeToggle() {
      this.offerCodeInputOpen = !this.offerCodeInputOpen;
    },
    async addPromoCode() {
      if (this.form.coupon_code == null || this.form.coupon_code.length === 0) {
        this.$toast.error("Please add promo code");
      } else {
        await axios
          .post(
            process.env.VUE_APP_API_CUS_URL + "/coupon-code",
            {
              check_in_date: this.form.check_in_date,
              check_out_date: this.form.check_out_date,
              coupon_code: this.form.coupon_code,
              total: this.bookingResult.total,
              extra_person_charge: this.form.extra_person_charge,
              festival_charge: this.form.festival_charge,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data?.message);
              this.form.coupon_amount = response.data.data?.coupon_amount;
              this.coupon_applied = true;
            } else {
              this.$toast.error(response.data?.message);
            }
          });
      }
    },
    linkBooking() {
      window.location = "/dashboard/VillaBooking";
    },
  },
};
</script>

<style scoped>
#dm .chooseDate {
  margin-top: 15px;
  margin-bottom: 15px;
}

#dm .dp__main {
  width: 100%;
  height: 100%;
  background: #e1ebd3 !important;
  padding: 10px !important;
}

#dm .dp__main div[aria-label="Datepicker input"] {
  width: 100%;
}

.other_member_detail {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: none;
}

.result_left {
  width: 30%;
}

.result_right {
  display: flex;
  width: 70%;
  float: right;
  justify-content: end;
}

.tab_inner_content {
  display: flex;
  flex-wrap: wrap;
}

.tab_inner_content_main {
  height: fit-content;
  margin: 10px;
  padding: 15px;
  display: inline-flex;
  width: 48%;
}

.tab_inner_content_image {
  width: 30%;
  height: 100px;
}

.tab_inner_content_image img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
}

.d_flex {
  display: flex;
}

.promocode_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab_inner_content_date {
  padding: 0 10px;
  font-size: 20px;
  width: 100%;
}

.tab_inner_content_add_user {
  display: flex;
  align-items: center;
  border-radius: 100px;
  margin-top: 0;
  padding: 0 10px 0 0px;
  background: transparent;
  font-size: 15px;
}

.aview_bar {
  padding: 8px;
}

.lightbox {
  top: 50% !important;
}

.v_bk_cat {
  text-align: center;
}

.labelBox {
  width: 50%;
}

.labelBox label {
  cursor: pointer;
}

a.main_button_user {
  background: #42aea3;
  border: none;
  color: #fff !important;
  border-radius: 100px;
  padding: 20px;
}

.main_reserves_row {
  align-items: center;
}

.tab_inner_content {
  height: 580px;
}

.tab_inner_content_main {
  display: flex;
  flex-direction: column;
}

.tab_inner_content_main .footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cccccc5e;
  padding-top: 15px;
  align-items: center;
}

.tab_inner_content_main .footer .amount {
  width: 120px;
}

a.text-white {
  color: #fff !important;
}

.total_price_col .check_numver {
  width: 145px;
}

.sale_price {
  font-size: 14px;
  margin-right: 5px;
}

.aview_bar.success {
  background-color: #5cb85c;
  border-color: #4cae4c;
  color: #fff;
  cursor: not-allowed;
}

.aview_bar.warning {
  background-color: #f0ad4e;
  color: #fff;
}

.aview_bar.danger {
  background-color: red;
  color: #fff;
}

.m15minus {
  margin: -15px;
}

.mt-1 {
  margin-top: 10px;
}

.mr-1 {
  margin-right: 10px;
}

#dm .aview_bar a {
  color: #fff !important;
}

#dm a.main_button_user {
  color: #fff !important;
}

#dm .memeber_list .type_title {
  margin-bottom: 10px;
}

#dm .pm_detail_col {
  justify-content: normal;
}

#dm .pm_detail_12:first-child {
  width: 50%;
}

#dm .pm_detail_12:nth-child(3) {
  width: 25%;
}

#dm .trash {
  padding: 12px 20px;
  background-color: #d13535;
}

#dm .trash.disbaled {
  opacity: 0.5;
  cursor: not-allowed;
}

#dm .pm_deatil_in {
  justify-content: space-between;
  width: 100%;
}

#dm .add_member {
  justify-content: end;
}

#dm .other_member_detail {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

#dm .main_button_user_square_plus {
  padding: 20px 15px;
  margin-right: 27px;
}

#dm .plus {
  margin-right: 0px;
}

#dm .error {
  margin-top: 5px;
}

#dm .main_reserves_row {
  position: relative !important;
}

#dm .main_reserves_row {
  position: relative !important;
  flex-wrap: wrap;
}

#dm .main_reserves_col.result_left {
  display: flex;
  width: 100%;
  text-align: end;
}

#dm .main_reserves_col_inner {
  margin: auto;
  margin-right: 0px;
}

#dm .main_reserves_col.result_right {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

#dm .main_reserves_col {
  width: 100%;
  /* display: flex; */
  text-align: end;
}

#dm button.main_button_user {
  margin: auto;
  margin-right: 0;
  margin-top: 17px;
}

#dm span.margin_top_10 {
  margin-top: 10px;
  display: block;
}

#dm .main_reserves_row {
  position: relative !important;
  flex-wrap: wrap;
  border-radius: 10px;
  box-shadow: 0px 0px 14px #dceae8;
}

#dm .new_bottom .main_reserves_row {
  justify-content: end;
}

#dm .new_bottom .check_inr_right {
  width: 50%;
  padding-top: 5px;
}

#dm .new_bottom li.check_rt_li .check_rt_div {
  padding: 10px 0;
}

#dm .new_bottom .total_price_col {
  margin-top: 0px;
  padding: 10px 0px;
}

#dm .new_bottom {
  justify-content: end;
}
#dm .new_bottom .main_button_user_custom {
  background: #42aea3;
  border: none;
  color: #fff;
  border-radius: 0 0 9px 9px;
  padding: 10px 20px;
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -21px;
}

#dm .vila_title {
  text-transform: capitalize;
  margin-bottom: 10px;
}

#dm p.reverse_error {
  color: red;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding: 10px;
}

#dm .w-100 {
  width: 100%;
}

#dm .villa_card_wrapper {
  width: 100%;
  display: flex;
}

#dm .main_class_inner_sd.select_image {
  width: 100%;
}

#dm .vialla_qty_main:nth-child(1) {
  margin-right: 5px;
}

#dm .vialla_qty_main:nth-child(2) {
  margin-left: 5px;
}

#dm .vialla_qty_main_inner {
  padding: 15px 15px;
}

#dm .vialla_qty_main select {
  width: 100%;
}

#dm .counter_wrapper {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  margin-top: 15px;
  flex-direction: column;
}

#dm .counter_wrapper .datePicker {
  margin-bottom: 15px;
}

#dm .pm_detail_col.counter.title {
  margin-bottom: 15px;
}

#dm .counter {
  justify-content: center !important;
}

#dm .counter .row {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}

#dm .sidebar_right_content {
  padding-top: 0;
}

#dm .sidebar_right_content {
  align-items: flex-start !important;
  padding-top: 15px;
  display: block !important;
  position: relative;
}

#dm .villa_card_owner_wrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

#dm .villa_card_owner_wrapper .main_button_user {
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px 25px;
}

#dm .offercodewrapper {
  display: flex;
  justify-content: end;
  margin-top: 5px;
}

#dm .offercodewrapper input {
  border: 1px solid #ccc;
  box-shadow: none;
  border-radius: 4px 0 0 4px;
  height: 28px;
  width: 160px;
}

#dm .offercodewrapper .offer_submit {
  background: #42aea3;
  border: none;
  color: #fff;
  border-radius: 0 4px 4px 0;
  padding: 7px 20px;
  font-size: 14px;
}

#dm .offercodelink {
  display: block;
  font-size: 12px;
  cursor: pointer;
}

#dm .main_button_wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

#dm button.main_button_user {
  margin-top: 25px;
}

#dm .villa_card_owner_other_wrapper {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 14px #dceae8;
  background: #fff;
  padding: 30px;
  margin-top: 20px;
}

#dm .villa_card_owner_wrapper .main_button_user {
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px 25px;
}

#dm .villa_card_owner_other_wrapper .row {
  display: flex;
}

#dm .villa_card_owner_other_wrapper .row .col-6 {
  display: block;
  width: 50%;
  padding: 15px 10px;
}

#dm .villa_card_owner_other_wrapper .row .col-6 label {
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
}

#dm .villa_card_owner_other_wrapper .row .col-6 input {
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 16px !important;
  width: inherit;
  border-bottom: 1px solid #a2dfc7;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  width: 100%;
}

#dm .villa_card_owner_other_wrapper .row .error {
  text-align: left;
  margin-top: 10px;
  color: red;
  font-size: 10.5px;
}

#dm .add_icon {
  cursor: pointer;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
  display: flex !important;
}
.justify-end {
  justify-content: end;
  display: flex !important;
}

.bg-transparent {
  background: transparent !important;
  box-shadow: none !important;
}

.align-center {
  align-items: center;
}

.mt-0 {
  margin-top: 0 !important;
}

#dm .check-box-wrapper {
  display: flex;
  align-items: center;
  color: #42aea3;
  cursor: pointer;
}

#dm .check-box-wrapper input {
  width: auto !important;
  margin: 0;
  margin-right: 10px;
}

#dm .site_bk_btn {
  cursor: pointer;
}

#dm .dp__input_icon_pad {
  color: #000;
}

#dm .imgContainer-zero {
  border: none;
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

#dm .imgContainer {
  /* border: 1px solid #42aea3; */
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

@media all and (min-width: 200px) and (max-width: 991px) {
  #dm .w3-container {
    padding: 25px 20px;
  }

  .row {
    margin-right: unset;
    margin-left: unset;
  }

  #dm .pm_detail_12 {
    width: 100% !important;
  }

  #dm .pm_detail_col .pm_detail_12:last-child {
    padding-top: 20px;
  }

  #dm .pm_detail_form .pm_detail_col:last-child .pm_detail_rt {
    display: flex !important;
    justify-content: center !important;
  }

  #dm .main_button_user_square_plus {
    margin-right: 0px;
  }

  #dm .new_bottom .check_inr_right {
    width: 100%;
  }

  #dm .main_reserves_col {
    justify-content: center;
  }

  #dm button.main_button_user {
    margin: unset;
    margin-right: 0px;
    margin-top: 17px;
  }

  .m15minus {
    margin: unset;
  }

  #dm .villa_card_wrapper {
    flex-wrap: wrap;
  }

  #dm .datePicker {
    margin: 15px;
  }

  #dm .pm_detail_block {
    padding: 0 15px;
  }
}
@media all and (min-width: 200px) and (max-width: 767px) {
  .mt-3 {
    margin-top: 15px;
  }

  #dm .ui-formSlide {
    overflow: unset;
  }

  #dm .counter_wrapper {
    margin-left: 15px;
    margin-right: 15px;
  }

  #dm .picnic_title {
      width: 100%;
      margin-bottom: 12px;
  }

  #dm .picnic_price {
    width: 50%;
  }

  #dm .counter .row.picnic_wrap{
    flex-wrap: wrap;
    justify-content: space-between !important; 
  }

  #dm .pmt{
    padding-top: 25px;
  }
}
</style>
